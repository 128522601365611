import { BrowserRouter as Router, Route, RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from './Router';

declare global {
  interface Window {
      config?: Config;
  }
}

function App() {
  return (
    <>
      <RouterProvider router={router}/>
    </>
  );
}

export default App;

