import { Col, Image } from "react-bootstrap";
import { useOutletContext} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';
import { IoDocument } from "react-icons/io5";
import CourseTable from './CourseTable';
import  { PageHeaderWithIcon, RectangleCardGrid } from "./shared";

function Education() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

     const card_info = [
        {
            className: 'card-rectangle blue-rectangle',
            title: 'Информация о реализуемых образовательных программах',
            imageSrc: 'icons/info.svg',
            paragraphs: [
              {
                text: 'Информация о программе «Дневник самоконтроля качества жизни»',
                onClick: () => window.open('/files/14_professional_developmen_program.pdf', '_blank'),
                className: 'save_doc',
              },
            ],
            children: 
            <>
                <h3>Дополнительная профессиональная программа – программа повышения квалификации «Дневник самоконтроля качества жизни»</h3>
                <p>Форма образовательной программы: очная.
                <br/>При реализации образовательной программы возможно при необходимости использование электронного обучения и дистанционных образовательных технологий.
                <br/>Информация о нормативных сроках обучения: 18 академических часов.
                <br/>Срок действия государственной аккредитации образовательной программы (при наличии государственной аккредитации): не требуется.
                <br/>Информация о языке, на котором осуществляется образование (обучение): обучение осуществляется на русском языке.</p>
            </>,
          },
    ] 
    const cards = [
        {
            className: 'card-rectangle blue-rectangle',
            title: 'Документы по программе',
            imageSrc: 'icons/doc.svg',
            paragraphs: [
              {
                text: 'Документы по программе',
                onClick: () => window.open('/files/14_professional_developmen_program.pdf', '_blank'),
                className: 'save_doc',
              },
              {
                text: 'Учебный план, аннотации, календарный учебный график, методическая информация образовательной программы',
                onClick: () => window.open('/files/15_education_program.pdf', '_blank'),
                className: 'save_doc',
              },
            ]
        },
        {
            className: 'card-rectangle blue-rectangle',
            title: 'Дополнительная информация',
            imageSrc: 'icons/add_info.svg',
            children: 
            <p>Информация об общей численности обучающихся по образовательной программе: обучение проходят  19 администраторов копий программы для ЭВМ «Дневник самоконтроля качества жизни».
            <br/>Информация о численности обучающихся по договорам об образовании, заключаемых при приёме на обучение за счёт средств физического и (или) юридического лица: обучение проводится в рамках договоров технической поддержки.
            <br/>Информация о численности обучающихся за счёт бюджетных ассигнований федерального бюджета (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.
            <br/>Информация о численности обучающихся за счёт бюджетных ассигнований бюджетов субъектов Российской Федерации (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.
            <br/>Информация о численности обучающихся за счёт бюджетных ассигнований местных бюджетов (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.
            <br/>Информация об уровне образования обучающихся: лица, имеющие среднее профессиональное и (или) высшее образование; лица, получающие среднее профессиональное и (или) высшее образование.
            <br/>Информация о коде и наименовании профессии, специальности, направления подготовки, научной специальности: не требуется.
            <br/>Информация о направлениях и результатах научной (научно-исследовательской) деятельности и научно-исследовательской базе для её осуществления: не требуется.
            <br/>Информация о результатах приёма по каждому направлению подготовки или специальности высшего образования, научной специальности с различными условиями приёма (на места, финансируемые за счёт бюджетных ассигнований федерального бюджета, бюджетов субъектов Российской Федерации, местных бюджетов, по договорам об образовании за счёт средств физических и (или) юридических лиц) с указанием средней суммы набранных баллов по всем вступительным испытаниям: не требуется.
            <br/>Информация о результатах перевода, восстановления и отчисления: не требуется.
            <br/>Образовательная организация оставляет за собой право обеспечивать взаимодействие с обучающимися посредством видеоконференцсвязи.</p>
        },
        {
            className: 'card-rectangle blue-rectangle',
            title: 'Лицензия на осуществление образовательной деятельности',
            imageSrc: 'icons/student.svg',
            paragraphs: [
              {
                text: 'Образовательная деятельность осуществляется на основании Лицензии от 28 декабря 2023 г. №Л035-01298-77/01010690 на осуществление образовательной деятельности, выданной Департаментом образования и науки города Москвы.',
              },
              {
                text: 'Скачать документ',
                onClick: () => window.open('/files/2_license.pdf', '_blank'),
                className: 'save_doc',
              },
            ]
        },
    ]

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Об организации / <b>Образование</b></p>
                        <h1>Образование</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Образование"
                    navigation="Главная / Об организации / Образование"
                    iconSrc="/header_icons/Education.svg"
                />

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardGrid cards={card_info} fullWidth={true} />
                    </div>
                </section>

                <section> 
                    <div className='main_container course_table'>
                        <h2>Учебные предметы, курсы, дисциплины (модули), практики, предусмотренные соответствующей образовательной программой (в ак. часах)</h2>
                        <CourseTable />
                    </div>
                </section>

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardGrid cards={cards} fullWidth={true} />
                    </div>
                </section>

                <Footer/>
            </div>
        </div>
    )
}

export default Education;