import { Image } from "react-bootstrap";
import { useOutletContext} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';
import Footer from './Footer';
import { OnlyPBlock, PageHeaderWithIcon } from "./shared";

function Equipment() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    const first_paragraphs = [
        {
            text: 'Место осуществления образовательной деятельности в соответствии с частью 4 статьи 91 Федерального закона от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации»: ',
            html: '<a href="https://yandex.ru/maps/-/CDBeMG2Z" style="color: var(--text-2)" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.'
        },
        {
            text: 'Учебные кабинеты АНО «ИДП им. Д. И. Менделеева» оборудованы столами, стульями, столом преподавателя, стулом преподавателя, компьютером, ТВ-экраном, магнитной доской, стеллажом для хранения учебного и дидактического материала.'
          },
    ]; 

    const paragraphs = [
        {
            text: 'В здании образовательной организации обеспечен доступ для инвалидов и лиц с ограниченными возможностями здоровья.'
          },
          {
            text: 'Информация о наличии объектов для проведения практических занятий: не требуется.'
          },
          {
            text: 'Сведения о наличии библиотек, объектов спорта, средств обучения и воспитания: не требуются.'
          },
          {
            text: 'Сведения об условиях питания и охраны здоровья обучающихся: питание обучающихся обеспечено в учреждениях общественного питания, расположенных на первом этаже офисного центра, где находится образовательная организация (г. Москва, ул. Никольская, д. 10). На занятиях преподавателями доводится информация, направленная на формирование здорового образа жизни.'
          },
          {
            text: 'Сведения о доступе к информационным системам и информационно-телекоммуникационным сетям: не требуется. Имеется оборудование и мощности для обеспечения взаимодействия с обучающимися посредством видеоконференцсвязи (по необходимости).'
          },
          {
            text: 'Сведения об электронных образовательных ресурсах, к которым обеспечивается доступ обучающихся: не требуется.'
          }
     ];

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Ресурсы / <b>Материально-техническое обеспечение и оснащённость образовательного процесса</b></p>
                        <h1>Материально-техническое обеспечение и оснащённость образовательного процесса</h1>
                    </div>
                </section>*/}
                <PageHeaderWithIcon
                    header="Материально-техническое обеспечение и оснащённость образовательного процесса"
                    navigation="Главная / Ресурсы / Материально-техническое обеспечение и оснащённость образовательного процесса"
                    iconSrc="/header_icons/Equipment.svg"
                />
                
                <OnlyPBlock paragraphs={first_paragraphs} n={true} />

                <section className='section_blue'>
                    <div className='main_container equipment'>
                        <Image src={showImages ? "images/equipment_1.png" : "images/nullimg.png"} />
                        <Image src={showImages ? "images/equipment_2.png" : "images/nullimg.png"}/>
                        <Image src={showImages ? "images/equipment_3.png" : "images/nullimg.png"}/>
                    </div>
                </section>

                <OnlyPBlock paragraphs={paragraphs} />

                <Footer/>
            </div>
        </div>
    )
}

export default Equipment;