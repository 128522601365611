import { Image } from "react-bootstrap";
import { useOutletContext} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';
import { PageHeaderWithIcon, RectangleCardWithImage, RectangleCardWithImageProps } from "./shared";

function AccessibleEnvironment() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

     const card: RectangleCardWithImageProps = {
        imageSrc: "images/accessible_environment.png",
        paragraphs: [
            {
                text: 'В здании образовательной организации обеспечен доступ для инвалидов и лиц с ограниченными возможностями здоровья.'
            }
        ]
    }

        
    return(
        <div>
            <div style={{ fontSize: `${fontScale}%` }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Ресурсы / <b>Доступная среда</b></p>
                        <h1>Доступная среда</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Доступная среда"
                    navigation="Главная / Ресурсы / Доступная среда"
                    iconSrc="/header_icons/AccessibleEnvironment.svg"
                />

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardWithImage {...card} />
                    </div>
                </section>

                <Footer/>
            </div>
        </div>
    )
}

export default AccessibleEnvironment;