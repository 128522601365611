import React from 'react';
import {useOutletContext} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from '../Menu';
import { RectangleCard, RectangleCardProps } from '.';

interface RectangleCardGridProps {
  cards: RectangleCardProps[];
  fullWidth?: boolean
}

export const RectangleCardGrid: React.FC<RectangleCardGridProps> = ({ cards, fullWidth }) => {

    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
        , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
       } = contextOutlet;

    const cardsLen = cards.length;
    const rows = Math.ceil(cardsLen / 2);

    console.log('fullWidth', fullWidth)

  return (
    <div className="rectangle-card-grid">
      {Array(rows)
        .fill(null)
        .map((_, index) => (
          <div 
            key={index} 
            className={
                (fontScale === 100 && getContrastClassName(contrast) === '' && showImages)
                ? index === 0
                    ? 'row-rectangle-card-grid row-rectangle-card-grid-first'
                    : 'row-rectangle-card-grid'
                : 'row-rectangle-card-grid-accesibility'
            }
          >
            {cards.slice(index * 2, (index + 1) * 2).map((card, cardIndex) => (
              <div
                key={cardIndex}
                //className={fullWidth ? 'col-full col-full-bottom' : `col-${cardsLen % 2 === 1 && index === rows - 1 ? 'full' : 'half'}`}
                className={
                  fullWidth
                    ? cardIndex === cardsLen - 1
                      ? 'col-full'
                      : 'col-full col-full-bottom'
                    : `col-${cardsLen % 2 === 1 && index === rows - 1 ? 'full' : 'half'}`
                }
              >
                <RectangleCard {...card} />
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

//export default RectangleCardGrid;