import {useOutletContext} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';
import Footer from './Footer';
import { PageHeaderWithIcon, RectangleCardGrid } from './shared';


function Structure() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    const cards = [
      {
        className: 'card-rectangle blue-rectangle left-rectangle',
        title: 'Структура',
        imageSrc: 'icons/share.svg',
        children:
        <p>Коллегиальный высший орган управления образовательной организации: Общее собрание Учредителя.
        <br/>Постоянно действующий коллегиальный орган управления образовательной организации: Наблюдательный совет.
        <br/>Коллегиальный орган, проводящий общественную экспертизу проектов и инициатив образовательной организации: Попечительский совет.
        <br/>Единоличный исполнительный орган образовательной организации: исполнительный директор.
        <br/>Структурное подразделение по осуществлению образовательной деятельности в организации: учебный центр.
        <br/>Руководителем учебного центра является исполнительный директор образовательной организации: Ефимов Иван Павлович.</p>
          ,
      },
      {
        className: 'card-rectangle blue-rectangle right-rectangle',
        title: 'Адреса образовательной организации',
        imageSrc: 'icons/map.svg',
        paragraphs: [
          {
              text: 'Место нахождения образовательной организации, в том числе структурного подразделения «Учебный центр»: ',
              html: '<a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.',
          },
          {
              text: 'Адрес официального сайта образовательной организации, в том числе структурного подразделения «Учебный центр», в информационно-телекоммуникационной сети «Интернет»: ',
              html: '<a href="/" className="a_link">индемп.рф</a>.',
          },
          {
            text: 'Адрес электронной почты образовательной организации, в том числе структурного подразделения «Учебный центр»: ',
            html: '<a href="mailto:info@indemp.ru" className="a_link">info@indemp.ru</a>',
          },
        ], 
      },
      {
          className: 'card-rectangle blue-rectangle',
          title: 'Положение',
          imageSrc: 'icons/student.svg',
          paragraphs: [
            {
              text: 'Положение об учебном центре автономной некоммерческой организации «Институт демографической политики имени Д. И. Менделеева»',
            },
            {
                  text: 'Скачать документ',
                  onClick: () => window.open('/files/16_regulation_on_the_UC.pdf', '_blank'),
                  className: 'save_doc',
            },
          ],
        },
    ]; 
     
    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Об организации / <b>Структура и органы управления образовательной организацией</b></p>
                        <h1>Структура и органы управления образовательной организацией</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Структура и органы управления образовательной организацией"
                    navigation="Главная / Об организации / Структура и органы управления образовательной организацией"
                    iconSrc="/header_icons/Structure.svg"
                />
                    
                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardGrid cards={cards} />
                    </div>
                </section>
                    
                <Footer/>
            </div>
        </div>
    )
}

export default Structure;