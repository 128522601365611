import { Image } from "react-bootstrap";
import { useOutletContext} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';
import Footer from './Footer';
import { PageHeaderWithIcon, RectangleCardWithImage, RectangleCardWithImageProps } from "./shared";

function InternationalCooperation() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    const card: RectangleCardWithImageProps = {
        imageSrc: "images/international_cooperation.png",
        paragraphs: [
            {
                text: 'Информация о заключенных и планируемых к заключению договорах с иностранными и (или) международными организациями по вопросам образования и науки: заключенных и планируемых к заключению договоров нет.'
            },
            {
                html: '<br/>'
            },
            {
                text: 'Информация о международной аккредитации образовательных программ: международная аккредитация образовательных программ отсутствует.'
            }
        ]
    }

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Ресурсы / <b>Международное сотрудничество</b></p>
                        <h1>Международное сотрудничество</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Международное сотрудничество"
                    navigation="Главная / Ресурсы / Международное сотрудничество"
                    iconSrc="/header_icons/InternationalCooperation.svg"
                />

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardWithImage {...card} />
                    </div>
                </section>

                <Footer/>
            </div>
        </div>
    )
}

export default InternationalCooperation;