import Table from 'react-bootstrap/Table';

import { AccessibilityState, getContrastClassName } from './Menu';
import { useOutletContext } from 'react-router-dom';

function CourseTable() {
  // const { showAccessibilityOptions, fontScale, contrast, showImages } = useAccessibility();
  const contextOutlet: AccessibilityState = useOutletContext() 
  const { showAccessibilityOptions, fontScale, contrast, showImages
    , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
   } = contextOutlet;
   
  return (
    //<Table responsive bordered={ false } className={`table_style ${getContrastClassName(contrast)}`}>
    <Table responsive className={`table_style ${getContrastClassName(contrast)}`}>
      <thead >
        <tr className={`table_header_first ${getContrastClassName(contrast)}`}>
          <td colSpan={6}>Модуль 1. Дневник самоконтроля качества жизни</td>
        </tr>
        <tr className={`table_header ${getContrastClassName(contrast)}`}>
          <th className='table_col_name'>Наименование<br></br>компонентов программы</th>
          <th>Лекции</th>
          <th>Практические занятия /<br></br>Самостоятельная работа</th>
          <th>Контроль</th>
          <th>Всего</th>
          <th>Форма контроля</th>
        </tr>
      </thead>
      <tbody className='table_body'>
        <tr>
          <td className='table_col_name'>Тема 1.1. Введение</td>
          <td>2</td>
          <td></td>
          <td></td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td className='table_col_name'>Тема 1.2. Начало работы в программе</td>
          <td>2</td>
          <td></td>
          <td></td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td className='table_col_name'>Тема 1.3. Администратор</td>
          <td>2</td>
          <td></td>
          <td></td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td className='table_col_name'>Тема 1.4. Социальный куратор</td>
          <td>2</td>
          <td></td>
          <td></td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td className='table_col_name'>Тема 1.5. Гражданин</td>
          <td>2</td>
          <td></td>
          <td></td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td className='table_col_name'>Тема 1.6. Практика</td>
          <td></td>
          <td>2</td>
          <td></td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td className='table_col_name'>Тема 1.7. Техподдержка</td>
          <td>2</td>
          <td></td>
          <td></td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td className='table_col_name'>Тема 1.8. Общие вопросы</td>
          <td>2</td>
          <td></td>
          <td></td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td className='table_col_name'>Итоговая аттестация (зачет)</td>
          <td></td>
          <td></td>
          <td>2</td>
          <td>2</td>
          <td>Тестирование</td>
        </tr>
        <tr>
          <td className='table_col_name'>Итого</td>
          <td>14</td>
          <td>2</td>
          <td>2</td>
          <td>18</td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
}

export default CourseTable;