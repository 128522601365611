import React from 'react'
import { AccessibilityState, getContrastClassName } from '../Menu';
import { useOutletContext } from 'react-router-dom';
import { Col, Image } from "react-bootstrap"; 

export interface PageHeaderWithIconProps {
    navigation: string;
    header: string;
    iconSrc: string;
}

export const PageHeaderWithIcon: React.FC<PageHeaderWithIconProps> = ({ navigation, header, iconSrc}) => {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
        , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
       } = contextOutlet;


  return (
    <section className='lead-header'>
        <div className='main_container'>
            <div>
                <p dangerouslySetInnerHTML={{ __html: navigation.split("/ ").slice(0, -1).join(" / ") + " / <b>" + navigation.split("/ ").slice(-1) + "</b>" }}></p>
                <h1>{header}</h1>
            </div>
            {(fontScale == 100 && getContrastClassName(contrast) == '' && showImages && iconSrc) && <Image src={iconSrc} />}
        </div>
    </section>
  )
}

//export default PageHeaderWithIcon