import { useOutletContext } from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';
import { OnlyPBlock, PageHeaderWithIcon } from "./shared";

function PlacesAdmission() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    const paragraphs = [
        {
            text: 'Зачисление в группы проводится по заявлениям от физических или юридических лиц без вступительных испытаний.'
        },
        {
            text: 'При поступлении на обучение в образовательную организацию заключается договор об оказании платных образовательных услуг.'
        },
        {
            text: 'Приём на обучение в АНО «ИДП им. Д. И. Менделеева» проводится в соответствии с возможной наполняемостью группы.'
        },
        {
            text: 'Набор в группы ведётся в течение всего календарного года.'
        },
        {
            text: 'Вакантных мест для приёма на обучение за счёт государственного бюджета не предусмотрено.'
        },
        {
            text: 'АНО «ИДП им. Д. И. Менделеева» предоставляет услуги на коммерческой основе.'
        },
    ] 

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Об организации / <b>Вакантные места для приёма (перевода) обучающихся</b></p>
                        <h1>Вакантные места для приёма (перевода) обучающихся</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Вакантные места для приёма (перевода) обучающихся"
                    navigation="Главная / Об организации / Вакантные места для приёма (перевода) обучающихся"
                    iconSrc="/header_icons/PlacesAdmission.svg"
                />

                <OnlyPBlock paragraphs={paragraphs} />

                <Footer/>
            </div>
        </div>
    )
}

export default PlacesAdmission;