import React from 'react'
import { useOutletContext } from 'react-router-dom';
import { AccessibilityState, getContrastClassName } from '../Menu';
import { Col, Image } from 'react-bootstrap';

export interface RectangleCardWithImageProps {
    className?: string;
    title?: string;
    iconSrc?: string;
    imageSrc?: string;
    paragraphs?: {
      text?: string;
      html?: string;
      onClick?: () => void;
      className?: string;
    }[];
}

export const RectangleCardWithImage: React.FC<RectangleCardWithImageProps> = ({
    className,
    title,
    iconSrc,
    imageSrc,
    paragraphs,
}) => {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
        , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
       } = contextOutlet;


    return (
        <Col className="card-rectangle blue-rectangle">
            <div className="main_row home-contacts-row">
                {title && <h2>{title}</h2>}
                {(fontScale == 100 && getContrastClassName(contrast) == '' && showImages && iconSrc) && (
                    <Image src={iconSrc} width={50} height={50} />
                )}
            </div>
            <div className='card-rectangle-with-img'>
                {imageSrc && (showImages ? <Image src={imageSrc} /> : <Image src="images/nullimgRectangleCardWithImage.png" />)}
                <div className='card-rectangle-with-img-text'>
                    {paragraphs?.map((paragraph, index) => (
                        <p key={index} onClick={paragraph.onClick} className={paragraph.className}>
                            {paragraph.text}
                            {paragraph.html && (
                                <span dangerouslySetInnerHTML={{ __html: paragraph.html }} />
                            )}
                        </p>
                    ))}
                </div>
            </div>
        </Col>
    )
}