import { useOutletContext } from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';
import { PageHeaderWithIcon, RectangleCardGrid } from './shared';
//import { PageHeaderWithIcon } from "./shared";


function PaidService() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    const docs = [
        {
            id: 1,
            name: "Положение о порядке оказания платных образовательных услуг",
            url: "/files/12_provision_of_paid_services.pdf"
        },
        { 
            id: 2,
            name: "Приказ об утверждении формы договора об оказании платных образовательных услуг", 
            url: "/files/13_application_of_the_form_of_agreements.pdf"
        },
        //{ 
        //    id: 3,
        //    name: "Приказ об утверждении стоимости обучения", 
        //    url: "/files/"
        //},
    ]

    const cards = [
        {
            className: 'card-rectangle blue-rectangle',
            title: 'Документы',
            imageSrc: 'icons/doc.svg',
            paragraphs: docs.map(({ name, url }) => {
                return { 
                    text: name,
                    onClick: () => window.open(url, '_blank'),
                    className: 'save_doc',
                 }
            }),
            children: <p>АНО «ИДП им. Д. И. Менделеева» оказывает услуги на коммерческой основе.</p>,
        },
    ] 

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Об организации / <b>Платные образовательные услуги</b></p>
                        <h1>Платные образовательные услуги</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Платные образовательные услуги"
                    navigation="Главная / Об организации / Платные образовательные услуги"
                    iconSrc="/header_icons/PaidService.svg"
                />

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardGrid cards={cards} fullWidth={true} />
                    </div>
                </section>
                
                <Footer/>
            </div>
        </div>
    )
}

export default PaidService;