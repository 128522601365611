import { useOutletContext} from "react-router-dom";
import { IoDocument } from "react-icons/io5";
import { AccessibilityState, getContrastClassName } from './Menu';
import Footer from './Footer';
import  { PageHeaderWithIcon, RectangleCardGrid } from "./shared";


const enumDocTypes = {
    GENERALDOC: 1,
    LOCALDOC: 2
}

function Document() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

     const docs = [
        {
            id: 1,
            name: "Устав образовательной организации",
            url: "/files/1_ustav.pdf",
            type: enumDocTypes.GENERALDOC
        },
        { 
            id: 2,
            name: "Свидетельство о государственной аккредитации – выписка из реестра лицензий на осуществление образовательной деятельности, выданные Департаментом образования и науки города Москвы", 
            url: "/files/2_license.pdf",
            type: enumDocTypes.GENERALDOC
        },
        { 
            id: 3,
            name: "Правила внутреннего распорядка обучающихся", 
            url: "/files/3_rules_for_students.pdf",
            type: enumDocTypes.GENERALDOC 
        },
        { 
            id: 4,
            name: "Правила внутреннего трудового распорядка", 
            url: "/files/4_labor_regulations.pdf",
            type: enumDocTypes.GENERALDOC
         },
        { 
            id: 5,
            name: "Положение о порядке проведения самообследования", 
            url: "/files/5_position_of_selfexamination.pdf",
            type: enumDocTypes.GENERALDOC 
        },
    
        { 
            id: 6,
            name: "Правила приёма на обучение по программам дополнительного профессионального образования", 
            url: "/files/6_rules_for_admission_of_additional_education.pdf",
            type: enumDocTypes.LOCALDOC
        },
        { 
            id: 7,
            name: "Правила приёма на обучение по дополнительным общеобразовательным общеразвивающим программам", 
            url: "/files/7_rules_of_admission_of_the_educational_program.pdf",
            type: enumDocTypes.LOCALDOC 
        },
        { 
            id: 8,
            name: "Положение о режиме занятий обучающихся", 
            url: "/files/8_regulations_on_mode_of_classes.pdf",
            type: enumDocTypes.LOCALDOC 
        },
        { 
            id: 9,
            name: "Формы, периодичность и порядок текущего контроля успеваемости и промежуточной аттестации обучающихся", 
            url: "/files/9_regulations_on_certification.pdf",
            type: enumDocTypes.LOCALDOC 
        },
        { 
            id: 10,
            name: "Порядок и основания перевода, отчисления и восстановления обучающихся", 
            url: "/files/10_order_of_transfer.pdf",
            type: enumDocTypes.LOCALDOC 
        },
        { 
            id: 11,
            name: "Порядок оформления возникновения, приостановления и прекращения отношений между образовательной организацией и обучающимися", 
            url: "/files/11_order_of_registration_of_relations.pdf",
            type: enumDocTypes.LOCALDOC 
        },
        { 
            id: 12,
            name: "Положение об оказании платных образовательных услуг", 
            url: "/files/12_provision_of_paid_services.pdf",
            type: enumDocTypes.LOCALDOC 
        },
        { 
            id: 13,
            name: "Порядок оказания платных образовательных услуг (образец договора с юридическим лицом и образец договора с физическим лицом)", 
            url: "/files/13_application_of_the_form_of_agreements.pdf",
            type: enumDocTypes.LOCALDOC 
        },
    ] 

    const cards = [
        {
            className: 'card-rectangle blue-rectangle',
            title: 'Общие документы',
            imageSrc: 'icons/doc.svg',
            paragraphs: docs.filter(doc => doc.type === enumDocTypes.GENERALDOC).map(({ name, url }) => {
                return { 
                    text: name,
                    onClick: () => window.open(url, '_blank'),
                    className: 'save_doc',
                 }
            }),
        },
        {
            className: 'card-rectangle blue-rectangle',
            title: 'Локальные нормативные акты образовательной организации по основным вопросам организации и осуществления образовательной деятельности',
            imageSrc: 'icons/student.svg',
            paragraphs: docs.filter(doc => doc.type === enumDocTypes.LOCALDOC).map(({ name, url }) => {
                return { 
                    text: name,
                    onClick: () => window.open(url, '_blank'),
                    className: 'save_doc',
                 }
            }),
        }
    ]

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Об организации / <b>Документы</b></p>
                        <h1>Документы</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Документы"
                    navigation="Главная / Об организации / Документы"
                    iconSrc="/header_icons/Document.svg"
                />

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardGrid cards={cards} fullWidth={true} />
                    </div>
                </section>

                <Footer/>
            </div>
        </div>
    )
}

export default Document;