import { useOutletContext} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';
import { OnlyPBlock, PageHeaderWithIcon } from "./shared";

function FinancialActivity() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

     const paragraphs = [
        {
            text: 'АНО «ИДП им. Д. И. Менделеева» не является получателем субсидий на выполнение государственного (муниципального) задания, целевых субсидий, а также бюджетных инвестиций. В связи с этим все финансовые показатели в плане финансово-хозяйственной деятельности и отчёта о финансово-хозяйственной деятельности, отражаются по деятельности от оказания платных образовательных услуг.'
        }, 
    ]  
     
    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Ресурсы / <b>Финансово-хозяйственная деятельность</b></p>
                        <h1>Финансово-хозяйственная деятельность</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Финансово-хозяйственная деятельность"
                    navigation="Главная / Ресурсы / Финансово-хозяйственная деятельность"
                    iconSrc="/header_icons/FinancialActivity.svg"
                />

                <OnlyPBlock paragraphs={paragraphs} />
                    
                <Footer />
            </div>
        </div>
    )
}

export default FinancialActivity;