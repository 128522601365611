import React from 'react';
import { useOutletContext} from "react-router-dom";
import { AccessibilityState, getContrastClassName } from './Menu';
import Footer from './Footer';
import { PageHeaderWithIcon, RectangleCardGrid } from './shared';


const BasicInfo: React.FC = () => {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
        , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
       } = contextOutlet;

       const cards = [
        {
          className: 'card-rectangle blue-rectangle left-rectangle',
          title: 'Наименование',
          imageSrc: 'icons/user.svg',
          paragraphs: [
            {
              text: 'Автономная некоммерческая организация «Институт демографической политики имени Д. И. Менделеева». Сокращённое наименование образовательной организации: АНО «ИДП им. Д. И. Менделеева».',
            },
            {
                text: 'Дата создания образовательной организации: 18 апреля 2022 г.',
            },
            {
                text: 'Место нахождения образовательной организации: ',
                html: '<a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.',
            },
          ],
        },
        {
          className: 'card-rectangle blue-rectangle right-rectangle',
          title: 'Сведения об учредителе',
          imageSrc: 'icons/doc.svg',
          paragraphs: [
            {
              text: 'Учредитель образовательной организации: Ассоциация межрегионального социально-экономического взаимодействия «Центральный Федеральный Округ».',
            },
          ],
          //children: <h3>Программа повышения квалификации «Дневник самоконтроля качества жизни»</h3>,
        },
        {
            className: 'card-rectangle blue-rectangle left-rectangle',
            title: 'Контакты образовательной организации',
            imageSrc: 'icons/mail.svg',
            paragraphs: [
              {
                text: 'Режим и график работы образовательной организации: ежедневно в рабочие дни с 9:00 до 18:00 по московскому времени.',
              },
              {
                  text: 'Контактный телефон образовательной организации: ',
                  html: '<br/><a href="tel:+7 (495) 221-69-91" className="a_link">+7 (495) 221-69-91</a>',
              },
              {
                text: 'Адрес электронной почты образовательной организации: ',
                html: '<a href="mailto:info@indemp.ru" className="a_link">info@indemp.ru</a>',
              },
              {
                text: 'Место осуществления образовательной деятельности: ',
                html: '<a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.',
            },
            ],
          },
          {
            className: 'card-rectangle blue-rectangle right-rectangle',
            title: 'Дополнительные сведения',
            imageSrc: 'icons/add_info.svg',
            children:
                <p>Образовательная организация не имеет представительств и филиалов.<br/>В отношении реализуемых в образовательной организации  дополнительных профессиональных программ и основных программ профессионального обучения отсутствуют федеральные государственные образовательные стандарты и образовательные стандарты.<br/>Образовательная организация оказывает услуги на коммерческой основе. В образовательной организации не предусмотрены стипендии и иные выплаты обучающимся, общежитие и интернат, трудоустройство выпускников.</p>
            ,
        },
      ];       
    
    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                {/*<section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <p className='location'>Главная / Об организации / <b>Основные сведения</b></p>
                        <h1>Основные сведения</h1>
                    </div>
                </section>*/}

                <PageHeaderWithIcon
                    header="Основные сведения"
                    navigation="Главная / Об организации / Основные сведения"
                    iconSrc="/header_icons/BasicInfo.svg"
                />

                <section className="card-section">
                    <div className="main_container">
                        <RectangleCardGrid cards={cards} />
                    </div>
                </section>

                <Footer/>
            </div>
        </div>
    )
}

export default BasicInfo;