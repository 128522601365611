import React from 'react'

export interface OnlyPBlockProps {
    paragraphs: {
        text: string;
        html?: string;
        className?: string;
    }[];
    n?: boolean; // делать ли разделители в строчку между строками
}

export const OnlyPBlock: React.FC<OnlyPBlockProps> = ({ paragraphs, n=false }) => {
    const len = paragraphs.length;

  return (
    <section className='section_light'>
        <div className='main_container'>
            {paragraphs?.map((paragraph, index) => (
                <>
                    <p key={index} className={paragraph.className}>
                        {paragraph.text}
                        {paragraph.html && (
                            <span dangerouslySetInnerHTML={{ __html: paragraph.html }} />
                        )}
                    </p>
                    {n && len !== (index + 1) && <br/>}
                </>
               
            ))}
        </div>
    </section>
  )
}

//export default OnlyPBlock